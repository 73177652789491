var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
    'react-code-input-container': true
  }, _obj[_vm.className] = !!_vm.className, _obj ),style:({ width: ((_vm.fields * _vm.fieldWidth) + "px") })},[(_vm.title)?_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',{staticClass:"react-code-input"},[_vm._l((_vm.values),function(v,index){return [_c('input',{key:(_vm.id + "-" + index),ref:_vm.iRefs[index],refInFor:true,style:({
          width: (_vm.fieldWidth + "px"),
          height: (_vm.fieldHeight + "px")
        }),attrs:{"type":_vm.type === 'number' ? 'tel' : _vm.type,"pattern":_vm.type === 'number' ? '[0-9]' : null,"autoFocus":_vm.autoFocus && !_vm.loading && index === _vm.autoFocusIndex,"data-id":index,"disabled":_vm.disabled,"required":_vm.required,"maxlength":"1"},domProps:{"value":v},on:{"input":_vm.onValueChange,"focus":_vm.onFocus,"keydown":_vm.onKeyDown}})]})],2),(_vm.loading)?_c('div',{staticClass:"loading",style:({lineHeight: (_vm.fieldHeight + "px")})},[_c('div',{staticClass:"blur"}),_c('svg',{staticClass:"spin",attrs:{"viewBox":"0 0 1024 1024","data-icon":"loading","width":"1em","height":"1em","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill":"#006fff","d":"M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"}})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }